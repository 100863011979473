// components/RoadSegmentPopup.jsx

import React from "react";
import { Popup } from "react-leaflet";
import './RoadSegmentPopup.css';

const RoadSegmentPopup = ({ segment, type }) => {
  const { name, route, milepost, currentConditions } = segment;

  const formatTimestamp = (timestamp) =>
    timestamp ? new Date(timestamp).toLocaleString() : "N/A";

  return (
    <Popup>
      <strong>{name || "Unnamed"}</strong>
      <br />
      Route: {route.routeName || "Unknown"} ({route.routeId || "N/A"})
      <br />
      Milepost: {milepost[type]} ({type === "primaryMP" ? "Start" : "End"})
      <br />
      {/* <br />
      <strong>Conditions:</strong> */}
      <br />
      {Array.isArray(currentConditions) && currentConditions.length > 0 ? (
        currentConditions
          .slice() // Create a shallow copy to avoid mutating the original array
          .sort((a, b) => {
            if (a.forecastAvailable === null && b.forecastAvailable === null) return 0; // Both null
            if (a.forecastAvailable === null) return -1; // a is null, push it to the end
            if (b.forecastAvailable === null) return 1; // b is null, push it to the end
            return 0;
          })
          .map((condition, index) => {
            // Calculate 50 years ago from the endTime
            const endTime = condition.endTime ? new Date(condition.endTime) : null;
            const startTime = condition.startTime ? new Date(condition.startTime) : null;
            const fiftyYearsAgo = endTime ? new Date(endTime.setFullYear(endTime.getFullYear() - 50)) : null;

            return (
              <div key={index}>
                {index !== 0 && <br />}
                <strong>{condition.forecastAvailable || "Condition"}:</strong>{" "}
                {condition.conditionDescription || "N/A"}
                <br />
                Start Time: {formatTimestamp(condition.startTime)}
                {fiftyYearsAgo && startTime && fiftyYearsAgo < startTime && (
                  <>
                    <br />
                    End Time: {formatTimestamp(condition.endTime)}
                  </>
                )}
                <br />
              </div>
            );
          })
      ) : (
        "None"
      )}
    </Popup>
  );
};

export default RoadSegmentPopup;
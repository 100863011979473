// styles.js
// Predefined colors for specific route names
export const routeColors = {
  "I-70": "#0f53ff", 
  "US-6": "#0f53ff", 
  "US-40": "#0f53ff", 
  "CO-9": "#0f53ff",  
};

// Default fallback color
export const defaultColor = "#808080";

// Function to determine overlay color based on feature properties
export const getOverlayColor = (feature) => {
  const routeName = feature.properties.routeName || "default";
  let color = routeColors[routeName] || defaultColor;

  const currentConditions = feature.properties.currentConditions || [];
  let shouldBeRed = false;
  let shouldBeYellow = false;

  for (const condition of currentConditions) {
    if (condition.forecastAvailable === null) {
      const description = condition.conditionDescription || "";
      const firstPart = description.substring(0, 9);
      const numbers = firstPart.match(/\d+/g); 
      if (numbers) {
        const parsedNumbers = numbers.map(Number);

        if (parsedNumbers.some((num) => num > 6 && num !== 21)) {
          shouldBeRed = true;
          break;
        }

        if (parsedNumbers.some((num) => [5, 4, 21].includes(num))) {
          shouldBeYellow = true;
        }
      }
    }
  }

  if (shouldBeRed) {
    color = "red";
  } else if (shouldBeYellow) {
    color = "yellow";
  }

  return color;
};

// GeoJSON layer style functions
export const segmentOverlayStyle = (feature) => {
  const overlayColor = getOverlayColor(feature);

  if (overlayColor) {
    let dashArray;
    if (overlayColor === 'red') {
      dashArray = '10,5'; // Dash pattern for red overlay
    } else if (overlayColor === 'yellow') {
      dashArray = '5,10'; // Dash pattern for yellow overlay
    }

    return {
      color: overlayColor,
      weight: 4,
      opacity: 1,
      //dashArray: dashArray,
      //dashOffset: 20,
    };
  }
};

export const segmentInnerStyle = (feature) => segmentOverlayStyle(feature);

export const segmentLeftOutlineStyle = (feature) => ({
  color: "#0a11d8", // Left outline color
  weight: 9, // Wider than the inner line
  opacity: 0.7,
});

export const segmentRightOutlineStyle = (feature) => ({
  color: "#0a11d8", // Right outline color
  weight: 7, // Slightly narrower than the left outline
  opacity: 0.7,
});

// Circle marker style for segments 
export const routeSegmentMarkerStyle = (showSegments) => ({
  color: showSegments ? "#000" : "#fff",
  fillColor: showSegments ? "#fff" : "#000",
  fillOpacity: showSegments ? 0.8 : 0.6,
  opacity: showSegments ? 0.6 : 0.4,
  radius: showSegments ? 5 : 3,
});

// Dynamic styling for incidents
export const incidentStyle = (feature) => {
  const severity = feature.properties.severity?.toLowerCase();
  let fillColor;

  switch (severity) {
    case "minor":
      fillColor = "yellow";
      break;
    case "major":
      fillColor = "orange";
      break;
    case "severe":
      fillColor = "red";
      break;
    default:
      fillColor = "gray";
      break;
  }

  return {
    color: fillColor,
    radius: 8,
    fillColor: fillColor,
    fillOpacity: 0.7,
  };
};

import React, { useState, useEffect } from 'react';
import './App.css';
import RouteComponent from './react-components/RouteComponent';
import FloatingToggleButton from './react-components/FloatingToggleButton';
import { DataProcessor } from './utils/DataProcessor.js';
import SimpleView from './SimpleView';
import RoadConditionsMap from "./react-components/map-component/RoadConditionsMap";

function App() {
  const [routes, setRoutes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [lastUpdated, setLastUpdated] = useState(''); // Store the last update time
  const [toggleAll, setToggleAll] = useState(false); // Default to expanded
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState(''); 
  const [currentView, setCurrentView] = useState('Map');  
  const [currentTabIndex, setcurrentTabIndex] = useState(1);  

  const openModal = () => {
    setAnimationClass('animate__bounceInLeft');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setAnimationClass('animate__bounceOutRight');
    setTimeout(() => setIsModalOpen(false), 650);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === 'modal-overlay') {
      closeModal();
    }
  };

  const handleViewChange = (view, idx) => {
    setCurrentView(view);
    setcurrentTabIndex(idx);
  }; 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/data');
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Fetch error:', response.status, response.statusText, errorText);
          throw new Error('Network response was not ok');
        }
        
        const { incidents, destinations, roadSegments, lastUpdated  } = await response.json();

        const dataProcessor = new DataProcessor();
        dataProcessor.importIncidents(incidents);
        dataProcessor.importDestinations(destinations);
        dataProcessor.importRoadConditions(roadSegments);
        console.log(dataProcessor.routes);
        setRoutes(dataProcessor.routes);  
        setLastUpdated(lastUpdated || new Date().toLocaleString('en-US', { timeZone: 'America/Denver' }));

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data.');
        setLoading(false);
      }
    };

    // Fetch data initially and set up polling
    fetchData();
  }, []); 

  const handleToggle = () => {
    setToggleAll((prevToggleAll) => !prevToggleAll);
  }; 

  if (loading) {
    return <div className="App">Loading data...</div>;
  }

  if (error) {
    return <div className="App">{error}</div>;
  }

  return ( 
    <div className={`App ${currentView === 'Map' ? 'map-view' : ''}`}>
      {/* <div>
        <RoadConditionsMap routes={routes}/>
      </div> */}
      
      {/* Normal buttons for larger screens */}
      {/* <div className="button-group">
        <button className="view-toggle-button" onClick={handleViewToggle}>
          {simpleView ? 'Main View' : 'Simple View'}
        </button>
        {!simpleView && <FloatingToggleButton isExpanded={toggleAll} onToggle={handleToggle} />}
        <button className="disclaimer-button" onClick={openModal}>
          Disclaimer
        </button>
      </div> */}
  
      {/* Fixed menu bar for smaller screens */}
      {/* <div className="fixed-menu-bar">
        <div className="menu-bar-actions">
          <button className="view-toggle-button" onClick={handleViewToggle}>
            {simpleView ? 'Main View' : 'Simple View'}
          </button>
          <div className="last-updated-main">
            Server Data From: {lastUpdated}
          </div>
          {!simpleView && <FloatingToggleButton isExpanded={toggleAll} onToggle={handleToggle} className="floating-toggle-button-small" />}
        </div>
        <button className="disclaimer-button" onClick={openModal}>
          Disclaimer
        </button>
      </div> */}

      {/* <div className="fixed-menu-bar">
        <div className="menu-bar-actions"> 
          <button
            className={`view-toggle-button ${currentView === 'UI' ? 'active' : ''}`}
            onClick={() => handleViewChange('UI', 0)}
          >
            UI View
          </button>
          <button
            className={`view-toggle-button ${currentView === 'Simple' ? 'active' : ''}`}
            onClick={() => handleViewChange('Simple', 1)}
          >
            Simple View
          </button>
          <button
            className={`view-toggle-button ${currentView === 'Map' ? 'active' : ''}`}
            onClick={() => handleViewChange('Map', 2)}
          >
            Map View
          </button>
        </div>
        <button className="disclaimer-button" onClick={openModal}>
          Disclaimer
        </button>
      </div> */}
      <div className="fixed-menu-bar">
  <div className="menu-bar-actions" style={{ '--active-tab': currentTabIndex }}>
    <div
      className={`tab ${currentView === 'UI' ? 'active' : ''}`}
      onClick={() => handleViewChange('UI', 0)}
    >
      UI View
    </div>
    <div
      className={`tab ${currentView === 'Map' ? 'active' : ''}`}
      onClick={() => handleViewChange('Map', 1)}
    >
      Map View
    </div>
    <div
      className={`tab ${currentView === 'Simple' ? 'active' : ''}`}
      onClick={() => handleViewChange('Simple', 2)}
    >
      Simple View
    </div>
  </div> 
</div>
{currentView === 'UI' &&<FloatingToggleButton isExpanded={toggleAll} onToggle={handleToggle} /> }
      {/* Rest of your component */}
      <div className='main-content'> 
      {currentView === 'UI' && (
          <>
            <h1 className="page-title">COTrip Incidents and Destinations</h1>
            {Object.keys(routes).length === 0 ? (
              <p className="no-data">No data available.</p>
            ) : (
              Object.keys(routes).map((routeKey) => (
                <RouteComponent
                  key={routeKey}
                  route={routes[routeKey]}
                  toggleAll={toggleAll}
                />
              ))
            )}
          </>
        )}
        {currentView === 'Simple' && (
          <SimpleView 
            routes={routes}
            lastUpdated={lastUpdated}
          />
        )}
        {currentView === 'Map' && 
          <div className='flex-map-container'>
            <RoadConditionsMap routes={routes} />
          </div>
        }
        {/* {simpleView ? (
          <SimpleView
            toggleView={handleViewToggle}
            routes={routes}
            lastUpdated={lastUpdated}
          />
        ) : (
          <> 
            <h1 className="page-title">
              COTrip Incidents and Destinations
            </h1>
            {Object.keys(routes).length === 0 ? (
              <p className="no-data">No data available.</p>
            ) : (
              Object.keys(routes).map((routeKey) => (
                <RouteComponent
                  key={routeKey}
                  route={routes[routeKey]}
                  toggleAll={toggleAll}
                />
              ))
            )}
          </>
        )} */} 
      </div>

      <div className="last-updated-main">Server Data From: {lastUpdated}</div>

      <button className="disclaimer-button" onClick={openModal}>
        Disclaimer
      </button>
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className={`modal-content animate__animated ${animationClass}`}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <div className="disclaimer-message">
              <p className="disclaimer-message-title"><strong>Data Disclaimer, Required By CDOT:</strong></p>
              <p>
                The data made available here has been modified for use from its original source, which is the State of Colorado, Department of Transportation (CDOT).              
              </p>
              <p>
                THE STATE OF COLORADO AND CDOT MAKES NO REPRESENTATIONS OR WARRANTY AS TO THE COMPLETENESS, ACCURACY, TIMELINESS, OR CONTENT OF ANY DATA MADE AVAILABLE THROUGH THIS SITE.              
              </p>
              <p>
                THE STATE OF COLORADO AND CDOT EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.             
              </p>
              <p>
                The data is subject to change as modifications and updates are complete. It is understood that the information contained in the Web feed is being used at one's own risk.             
              </p> 
            </div> 
          </div>
        </div>
      )}
    </div>
  );
}

export default App;


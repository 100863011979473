export class Destination {
    constructor(name, travelTime, id, lastUpdated, segmentParts) {
        this.displayName = name; // Name of the travel time segment
        this.travelTime = Math.round(travelTime / 60); // Convert travel time to minutes
        this.id = id; // Unique identifier for the travel time
        this.lastUpdated = lastUpdated; // ISO-8601 last updated timestamp
        this.segmentParts = segmentParts || []; // Array of segment parts
    }

    toMarkdown() {
        return `| ${this.name} | ${this.travelTime} mins | ${this.lastUpdated} |\n`;
    }
}

import React, { useState, useRef, useEffect } from "react";
import SubrouteAccordion from './SubrouteAccordion';
import IncidentCard from './IncidentCard';
import './RouteComponent.css';

function RouteComponent({ route, toggleAll }) {
  const { name, globalIncidents = [], alerts = {}, subroutes = {} } = route;
  const [isExpanded, setIsExpanded] = useState(false); // Individual toggle state
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  // Synchronize with toggleAll state
  useEffect(() => {
    if (toggleAll) {
      setIsExpanded(true);
      setContentHeight(contentRef.current?.scrollHeight || 0);
    } else {
      setIsExpanded(false);
      setContentHeight(0);
    }
  }, [toggleAll]);

  // Handle individual toggles
  const toggleExpansion = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  // Update content height for individual toggles
  useEffect(() => {
    if (isExpanded) {
      setContentHeight(contentRef.current?.scrollHeight || 0);
    } else {
      setContentHeight(0);
    }
  }, [isExpanded]);

  return (
    <div className={`route-container ${isExpanded ? 'expanded' : ''}`}>
      <div className="route-top" onClick={toggleExpansion}>
        <h2 className="route-name">{name}</h2>
        <div className="route-restrictions">
          <h3 className="route-restrictions-header">🔒 Restrictions:</h3>
          {globalIncidents.length > 0 || Object.keys(alerts).length > 0 ? (
            <div className="road-alerts">
              {Object.keys(alerts).map((alertKey, index) => (
                <div key={alertKey} className="alert-message">
                  {alerts[alertKey]} 
                </div>  
              ))}
            </div>
          ) : (
            <div className="no-restrictions">
              🟢No restrictions reported. Expand widget for potential one-way restrictions.
            </div>
          )}
        </div>
      </div>
      <div
        className="route-content"
        ref={contentRef}
        style={{
          maxHeight: isExpanded ? `${contentHeight}px` : "0px",
          overflowY: 'hidden',
          transition: 'max-height 0.4s cubic-bezier(0.33, 1, 0.67, 1)',
          padding: isExpanded ? '15px' : '0',
        }}
      >
        <div className="incident-cards-container">
          {globalIncidents.map((incident, index) => (
            <IncidentCard key={index} incident={incident} />
          ))}
        </div>
        <div className="subroutes-container">
          {Object.keys(subroutes).map((subrouteKey, index) => (
            <SubrouteAccordion key={subrouteKey} subroute={subroutes[subrouteKey]} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default RouteComponent;
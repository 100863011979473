// utils.js

// Convert road segments to GeoJSON
export const convertRoadSegmentsToGeoJSON = (roadSegments) => {
    if (!Array.isArray(roadSegments) || roadSegments.length === 0) {
      return null;
    }
  
    return {
      type: "FeatureCollection",
      features: roadSegments.map((segment) => ({
        type: "Feature",
        geometry: {
          type: segment.geometry.type,
          coordinates: segment.geometry.coordinates,
        },
        properties: {
          id: segment.id,
          name: segment.name,
          routeId: segment.route.routeId,
          routeName: segment.route.routeName,
          primaryMP: Math.floor(segment.milepost.primaryMP * 10) / 10,
          secondaryMP: Math.floor(segment.milepost.secondaryMP * 10) / 10,
          primaryLatitude: segment.location.primary.latitude,
          primaryLongitude: segment.location.primary.longitude,
          secondaryLatitude: segment.location.secondary.latitude,
          secondaryLongitude: segment.location.secondary.longitude,
          currentConditionsSummary: segment.currentConditionsSummary,
          currentConditions: segment.currentConditions,
          forecastAvailable: segment.forecastAvailable,
        },
      })),
    };
  };
  
  // Convert incidents to GeoJSON
  export const convertIncidentsToGeoJSON = (routes) => {
    const features = [];
  
    Object.values(routes).forEach((route) => {
      Object.values(route.subroutes).forEach((subroute) => {
        subroute.incidents.forEach((incident) => {
          features.push({
            type: "Feature",
            geometry: incident.geometry,
            properties: {
              id: incident.properties.id,
              severity: incident.properties.severity,
              status: incident.properties.status,
              startMarker: incident.properties.startMarker,
              endMarker: incident.properties.endMarker,
              type: incident.properties.type,
              travelerInformationMessage: incident.properties.travelerInformationMessage,
              lastUpdated: incident.properties.lastUpdated,
              routeName: incident.properties.routeName,
            },
          });
        });
      });
    });
  
    return {
      type: "FeatureCollection",
      features,
    };
  };
  
// components/ToggleControls.jsx

import React from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

const ToggleControls = ({
  routes,
  routeVisibility,
  toggleRouteVisibility,
  showSegments,
  setShowSegments,
  showIncidents,
  setShowIncidents,
}) => {
  const map = useMap(); // Access the map instance
  const controlRef = React.useRef();

  // Prevent interaction events from propagating to the map
  React.useEffect(() => {
    if (controlRef.current) {
      const controlElement = controlRef.current;

      L.DomEvent.disableClickPropagation(controlElement);
      L.DomEvent.disableScrollPropagation(controlElement);
    }
  }, []);

  return (
    <div
      ref={controlRef}
      onMouseEnter={() => map.dragging.disable()}
      onMouseLeave={() => map.dragging.enable()}
      style={{
        position: "absolute",
        bottom: "15px",
        right: "0px",
        background: "white",
        padding: "10px",
        borderRadius: "5px 5px 0px 0px", 
        zIndex: 1000,
        backgroundColor: "hsla(0, 0%, 20%, 1)",
        color: "hsla(0, 0%, 75%, 1)", 
      }}
    >
      <h4 style={{ margin: "5px 0 5px 0", color: "#fff", }}>Toggle Routes</h4>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "3px",
        }}
      >
        {Object.keys(routes).map((routeName) => (
          <label key={routeName} style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={routeVisibility[routeName]}
              onChange={() => toggleRouteVisibility(routeName)}
            />
            {routeName}
          </label>
        ))}
      </div>
      <hr />
      <label>
        <input
          type="checkbox"
          checked={showSegments}
          onChange={() => setShowSegments(!showSegments)}
        />
        Start/End Markers
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          checked={showIncidents}
          onChange={() => setShowIncidents(!showIncidents)}
        />
        {showIncidents ? 'Hide Incidents' : 'Show Incidents'}
      </label>
    </div>
  );
};

export default ToggleControls;

import React from "react";
import './SimpleView.css';

function SimpleView({ routes, lastUpdated }) {
  const simpleViewStyle = {
    backgroundColor: "white",
    color: "black",
    minHeight: "100vh",
    padding: "20px",
    boxSizing: "border-box",
  };

  return (
    <div style={simpleViewStyle}> 
      <h1 className="simple-view-title-simple">Simple View</h1>
      <p className="data-last-updated-simple">
        <strong>Data Last Updated:</strong> {lastUpdated}
      </p>
      {Object.keys(routes).length === 0 ? (
        <p className="no-data-message-simple">No data available.</p>
      ) : (
        <div className="routes-container-simple">
          {Object.keys(routes).map((routeKey) => {
            const route = routes[routeKey];
            const { globalIncidents = [], alerts = {}, subroutes = {}, roadSegments = {} } = route;

            return (
              <div key={routeKey} className="route-section-simple">
                <h2 className="route-name-simple">{route.name}</h2>
{/* 
                {roadSegments.length > 0 && (

                  <div className="test">
                    {roadSegments.map((roadSegment, index) => (
                    <li key={index}>
                      <p>{roadSegment.name}</p>
                      <p>{roadSegment.id}</p>
                      <p>{roadSegment.nameId}</p>
                    </li>
                  ))}
                  </div>
                )} */}


                {/* Global Incidents */}
                {globalIncidents.length > 0 && (
                  <div className="global-incidents-simple">
                    <h3 className="section-title-simple">Global Incidents:</h3>
                    <ul className="global-incidents-list-simple">
                      {globalIncidents.map((incident, index) => (
                        <li key={index} className="incident-item-simple">
                          <p>
                            <strong>{incident.properties.type}</strong>:{" "}
                            {incident.properties.travelerInformationMessage}
                          </p>
                          <p>
                            <strong>Severity:</strong> {incident.properties.severity}
                          </p>
                          <p>
                            <strong>Start Time:</strong>{" "}
                            {new Date(
                              incident.properties.schedule.startTime
                            ).toLocaleString("en-US", {
                              timeZone: "America/Denver",
                            })}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Alerts */}
                <div className="alerts-section-simple">
                  <h3 className="section-title-simple">Restrictions:</h3>
                  {Object.keys(alerts).length > 0 ? (
                    <ul className="alerts-list-simple">
                      {Object.entries(alerts).map(([key, value]) => (
                        <li key={key} className="alert-item-simple">
                          {value}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="no-alerts-message-simple">No restrictions reported.</p>
                  )}
                </div>

                {/* Subroutes */}
                <div className="subroutes-section-simple">
                  <h3 className="section-title-simple">Subroutes:</h3>
                  {Object.keys(subroutes).length > 0 ? (
                    Object.keys(subroutes).map((subrouteKey) => {
                      const subroute = subroutes[subrouteKey];
                      return (
                        <div key={subrouteKey} className="subroute-simple">
                          <h4 className="subroute-direction-simple">{subroute.direction}</h4>

                          {/* Travel Times */}
                          {subroute.destinations.length > 0 && (
                            <table className="travel-times-table-simple">
                              <thead>
                                <tr>
                                  <th>Section</th>
                                  <th>Travel Time</th>
                                  <th>Last Updated</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subroute.destinations.map((destination, index) => (
                                  <tr key={index} className="destination-row-simple">
                                    <td>{destination.displayName}</td>
                                    <td>{destination.travelTime} mins</td>
                                    <td>
                                      {new Date(
                                        destination.lastUpdated
                                      ).toLocaleString("en-US", {
                                        timeZone: "America/Denver",
                                      })}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}

                          {/* Incidents */}
                          <div className="incidents-section-simple">
                            {subroute.incidents.length > 0 ? (
                              <ul className="incidents-list-simple">
                                {subroute.incidents.map((incident, index) => {
                                  const { properties } = incident;
                                  if (!properties) return null;

                                  return (
                                    <li key={index} className="incident-item-simple">
                                      <p>
                                        <strong>Type/Category:</strong>{" "}
                                        {properties.type} / {properties.category}{" "}
                                        {" - "}
                                        <strong>Severity:</strong>{" "}
                                        {properties.severity}
                                      </p>
                                      <div className="incident-description-simple">
                                        <strong>Description:</strong>
                                        <p className="incident-message-simple">
                                          {properties.travelerInformationMessage}
                                        </p>
                                      </div>
                                      <div
                                        className="incident-details-simple"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "10px",
                                        }}
                                      >
                                        <p>
                                          <strong>Start Time:</strong>{" "}
                                          {new Date(
                                            properties.startTime
                                          ).toLocaleString("en-US", {
                                            timeZone: "America/Denver",
                                          })}
                                        </p>
                                        {properties.detectionSource && (
                                          <p>
                                            <strong>Detected By:</strong>{" "}
                                            {properties.detectionSource}
                                          </p>
                                        )}
                                      </div>
                                      {properties.vehiclesInvolved > 0 && (
                                        <p>
                                          <strong>Vehicles Involved:</strong>{" "}
                                          {properties.vehiclesInvolved}
                                        </p>
                                      )}
                                      {properties.fatalities > 0 && (
                                        <p>
                                          <strong>Fatalities:</strong>{" "}
                                          {properties.fatalities}
                                        </p>
                                      )}
                                      {properties.additionalImpacts &&
                                        properties.additionalImpacts.length > 0 && (
                                          <div className="additional-impacts-simple">
                                            <strong>Additional Impacts:</strong>
                                            <ul>
                                              {properties.additionalImpacts.map(
                                                (impact, index) => (
                                                  <li
                                                    key={index}
                                                    className="impact-item-simple"
                                                  >
                                                    {impact}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      {properties.detours &&
                                        properties.detours.length > 0 && (
                                          <div className="detours-simple">
                                            <strong>Detours:</strong>
                                            <ul>
                                              {properties.detours.map(
                                                (detour, index) => (
                                                  <li
                                                    key={index}
                                                    className="detour-item-simple"
                                                  >
                                                    {detour.description}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                      {properties.lastUpdatedBy && (
                                        <p className="last-updated-by-simple">
                                          <strong>Last Updated By:</strong>{" "}
                                          {properties.lastUpdatedBy}
                                        </p>
                                      )}
                                      <p className="last-updated-simple">
                                        <em>
                                          Last Updated:{" "}
                                          {new Date(
                                            properties.lastUpdated
                                          ).toLocaleString("en-US", {
                                            timeZone: "America/Denver",
                                          })}
                                        </em>
                                      </p>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <p className="no-incidents-message-simple">
                                No incidents reported.
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="no-subroutes-message-simple">
                      No subroutes available.
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SimpleView;

import React, { useState } from 'react';
import './FloatingToggleButton.css';

function FloatingToggleButton({ isExpanded, onToggle, className }) {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(true);
    onToggle();
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  return (
    <div className={`${className || 'floating-toggle-button'}`}>
      <img
        src={isExpanded ? '/imgs/collapse50.png' : '/imgs/expand50.png'}
        alt={isExpanded ? 'Collapse All' : 'Expand All'}
        className={`toggle-icon ${isAnimating ? 'clicked' : ''}`}
        onClick={handleClick}
        onAnimationEnd={handleAnimationEnd}
      />
    </div>
  );
}

export default FloatingToggleButton;
